Vue.component("form-wrapper", {
    props: {
        selectedexternal: {
            type: Number,
            default: 0
        }
    },
    data: function () {
        return {
            external: this.selectedexternal
        }
    },
    mounted () {

    },
    methods: {
        basicFunction: function() {

        },
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha) {
                        this.$root.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });

            // recaptcha resetten
            //this.$refs.recaptcha.reset();
        },
    },
});
