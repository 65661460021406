Vue.component("postcode-api", {
    data: function () {
        return {
            street: null,
            number: null,
            postcode: null,
            city: null,
            province: null,
        }
    },
    methods: {
        fetchAddress() {
            if(this.street == null) {
                axios.post('/address', {
                    postcode: this.postcode,
                    number: this.number,
                }).then(response => {
                    this.street = response.data.data.street;
                    this.number = response.data.data.number;
                    this.postcode = response.data.data.postcode;
                    this.city = response.data.data.city;
                    this.province = response.data.data.province;
                }).catch(function (response) {
                    //console.log(response);
                });
            }
        }
    },
});
