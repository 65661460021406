Vue.component("modal-delete-vacancy", {
    data: function () {
        return {
            isOpen: false,
        }
    },
    mounted: function () {

    },
    watch: {
        isOpen: function(){

        }
    },
    methods: {
        deleteVacancy(route){
            axios.delete(route)
            .then(response => {
                window.location = response.data.target;
            });

        },
    },
});
