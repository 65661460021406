Vue.component("form-multiselect", {
    props: ['parentValues', 'provinceNamesList'],
    data: function () {
        return {
            provinces: [],
            provinceNames: [],
            provinceNamesConcat: 'Geen provincie geselecteerd',
            openDrawer: false,
        }
    },
    mounted: function () {
        this.provinces = this.parentValues;
        this.provinceNames = this.provinceNamesList;

        this.$root.$on('filters::update', (sectors, provincies) => {
            this.provinces = provincies;
        })
    },
    watch: {
        provinces: function(){
            this.concatSelectedProvinces();
        },
    },
    methods: {
        concatSelectedProvinces(){
            let that = this;
            that.provinceNamesConcat = [];
            [].forEach.call(that.provinces, (provinceID) => {
                that.provinceNamesConcat.push(that.provinceNames[provinceID]);
            });

            that.provinceNamesConcat = that.provinceNamesConcat.join(', ');

            if( that.provinceNamesConcat == ''){
                that.provinceNamesConcat = 'Geen provincie geselecteerd'
            }
        },
    },
});
