Vue.component("modal-edit-location", {
    data: function () {
        return {
            isOpen: false,
            location: {},
        }
    },
    mounted: function () {

    },
    watch: {
        isOpen: function(){

        }
    },
    methods: {
        newLocation: function(){
            this.location = {

            }
        },
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha) {
                        this.$root.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });

            // recaptcha resetten
            //this.$refs.recaptcha.reset();
        },
        deleteLocation(route){
            let that = this;
            axios.delete(route)
                .then(response => {
                    that.isOpen = false;
                });

        },
    },
});
