Vue.component('form-component', {
    data: function () {
        return {
            hasCaptcha: true
        }
    },
    methods: {
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$el;
                let analytics = null;

                if(form.dataset.analytics){
                    analytics = form.dataset.analytics;
                }

                if (result && analytics) {
                    if (!this.hasCaptcha) {
                        this.$root.submitForm(form, analytics);
                    } else {
                        this.$refs.recaptcha.execute();
                    }
                } else if (result) {
                    this.$root.submitForm(form);
                }
            });

            // recaptcha resetten
            this.$refs.recaptcha.reset();
        },
        onCaptchaVerified: function () {
            this.submit();
        }
    },
});
