Vue.component("vacancies-filter", {
    props: ['startProvinces', 'startSectors', 'startKeywords'],
    data: function (){
        return {
            isOpen: false,
            isInitialized: false,
            provinces: [],
            sectors: [],
            keywords: '',
            ajaxTimeoutDuration: 300,
            ajaxTimeout: '',
            inputTimeoutDuration: 0
        }
    },
    mounted: function () {
        this.sectors = JSON.parse(this.startSectors);
        this.provinces = JSON.parse(this.startProvinces);
        this.keywords = JSON.parse(this.startKeywords);
        this.$root.$emit('filters::update', this.sectors, this.provinces, this.keywords);

        setTimeout(() => {
            this.isInitialized = true;
        }, 750);
    },
    watch: {
        'provinces' : function(){
            this.queueAjaxCall()
        },
        'sectors' : function(){
            this.queueAjaxCall()
        },
        'keywords': function() {
            if (this.inputTimeoutDuration >= 0) {
                clearTimeout(this.inputTimeoutDuration);
            }
            this.inputTimeoutDuration = window.setTimeout(() => {
                this.queueAjaxCall();
            }, 500);
        }
    },
    methods: {
        queueAjaxCall: function(){
            if(this.isInitialized) {
                clearTimeout(this.ajaxTimeout);
                this.ajaxTimeout = setTimeout(this.getFilterResults , this.ajaxTimeoutDuration);

                this.$root.$emit('filters::update', this.sectors, this.provinces);
            }
        },
        getFilterResults: function(){
            let provinces = this.provinces.map(p => 'provinces[]=' + p);
            let sectors = this.sectors.map(s => 'sectors[]=' + s);
            let keywords = 'keywords='+ this.keywords;
            let filters = [].concat(provinces, sectors, keywords).join('&');

            if(filters.length > 0){
                filters = '?' + filters;
            }

            let requestUrl = window.location.pathname + filters;

            window.history.pushState({
                url: requestUrl
            }, window.title, requestUrl);

            axios({
                method: 'get',
                url: requestUrl,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }).then(function(response) {
                let list = document.querySelector('[vacancies-list]');
                let parent = list.parentNode;

                parent.removeChild(list);
                parent.innerHTML = response.data;
            }).catch(function (response) {
                console.log(response);
            });
        }
    },
});
