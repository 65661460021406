Vue.component("vacancies-alert", {
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    data: function () {
        return {
            isOpen: false,
            animationTimeout: false,
            animationTimeoutClose: false,
            sectors: [],
            provinces: [],
            isMounted: false,
            hasCaptcha: false,
            captchaExecuted: false,
            captchaEvent: null,
        }
    },
    mounted: function () {

        this.$root.$on('filters::update', (sectors, provincies) => {
            this.sectors = sectors;
            this.provinces = provincies;
        })
    },
    watch: {
        isOpen(){
            if(this.isOpen){

                this.$root.$emit('alert::reset', (sectors, provincies) => {
                    this.sectors = sectors;
                    this.provinces = provincies;
                })
            }
        }
    },
    methods: {
        submit: function ($event) {
            let that = this;

            this.$validator.validateAll().then(result => {
                let analytics = null;
                if ($event.target.dataset.analytics){
                    analytics = $event.target.dataset.analytics;
                }

                if (result) {
                    if (!this.hasCaptcha || this.captchaExecuted) {
                        if (analytics) {
                            this.$root.submitForm($event.target, analytics);
                        } else {
                            this.$root.submitForm($event.target);
                        }
                        that.captchaEvent = null;
                    } else {
                        that.captchaEvent = $event;
                        this.$refs.recaptcha.execute();
                    }
                } else {
                    // recaptcha resetten
                    this.$refs.recaptcha.reset();
                    this.captchaExecuted = false;
                }
            });
        },
        onCaptchaVerified: function () {
            this.captchaExecuted = true;
            this.submit(this.captchaEvent);
        },
        openForm: function () {
            let that = this;

            if(this.isOpen){
                that.$refs.alert__form_container.style.height = that.$refs.alert__form.clientHeight + 'px';

                that.animationTimeoutClose = setTimeout(function(){
                    that.$refs.alert__form_container.style.height = '0px';
                }, 50);

                // Verwijder de timeout voor aanpassing naar height: auto.
                clearTimeout(this.animationTimeout);

            } else {

                that.$refs.alert__form_container.style.height = that.$refs.alert__form.clientHeight + 'px';

                // Verwijder de timeout voor aanpassing naar height: auto.
                clearTimeout(this.animationTimeoutClose);

                // Zet hoogte naar auto, na de animatie duratie, om uitloop van het formulier te faciliteren
                that.animationTimeout = setTimeout(function(){
                    that.$refs.alert__form_container.style.height = 'auto';
                }, 300);
            }
            this.isOpen = !this.isOpen;
        },
    },
});
