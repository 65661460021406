Vue.component("locations-list", {
    props: ['locationList'],
    data: function (){
        return {
            locations: this.locationList,
            locationForm: {},
            isFormModalOpen: false,
            deleteModal: false,
        }
    },
    mounted: function () {
        this.clearLocationForm();
    },
    watch: {

    },
    methods: {
        clearLocationForm: function(){
            this.locationForm = {
                locationID: '',
                name: '',
                street: '',
                housenumber: '',
                zipcode: '',
                city: '',
                province_id: '',
                contact_name: '',
                contact_email: '',
                contact_phone: '',
            }
        },
        editLocation: function(locationIndex){
            this.clearLocationForm();
            this.locationForm       = this.locations[locationIndex];
            this.isFormModalOpen    = !this.isFormModalOpen;
        },
        addLocation: function(){
            this.clearLocationForm();
            this.locationForm.locationID    = 0;
            this.isFormModalOpen            = !this.isFormModalOpen;
        },
        submit: function () {
            this.$validator.validateAll().then(result => {
                let form = this.$refs.formLocation;

                if (result) {
                    this.$root.submitForm(form);
                    this.isFormModalOpen = false;

                    if(this.locationForm.locationID == 0) {
                        this.locations.push(this.locationForm);
                        location.reload();
                    }
                }
            });

        },
        onCaptchaVerified: function () {
            this.submit();
        },
        deleteLocation(id){
            let that = this;

            axios.delete('/beheer/locations/'+id)
            .then(response => {
                that.locations.forEach((location, index) => {
                    if(location.locationID == id){
                        that.locations.splice(index, 1);
                    }
                })

                that.isFormModalOpen    = false;
                that.deleteModal        = false;
            });
        },
        fetchAddress() {
            if(this.locationForm.street == '') {
                axios.post('/address', {
                    postcode: this.locationForm.zipcode,
                    number: this.locationForm.housenumber,
                }).then(response => {
                    this.locationForm.street = response.data.data.street;
                    this.locationForm.housenumber = response.data.data.number;
                    this.locationForm.zipcode = response.data.data.postcode;
                    this.locationForm.city = response.data.data.city;
                    this.locationForm.province_id = this.getProvinceId(response.data.data.province);
                }).catch(function (response) {
                    //console.log(response);
                });
            }
        },
        getProvinceId(province) {
            let options = document.getElementById('province').options;
            for(let x = 0; x < options.length; x++) {
                if(options[x].text == province) {
                    return options[x].value;
                }
            }
        }
    },
});
