Vue.component("modal", {
    template:
        '<div class="form_modal" modal-delete-vacancy modal-state="hide">\n' +
        '    <div class="form_modal__bg" form-modal-trigger></div>\n' +
        '    <div class="form_modal__content">\n' +
        '        <slot/>\n' +
        '\n' +
        '        <div class="close" form-modal-trigger>&times</div>\n' +
        '    </div>\n' +
        '</div>',
    data: function () {
        return {
            default: true
        }
    },
    mounted: function () {
        console.log('vue modal active');
    },
    methods: {
        defaultFunction: function(){

        },
    },
});
