Vue.component("form-fileupload", {
    data: function () {
        return {
            file: '',
        }
    },
    mounted: function () {
        this.setFilename();
    },
    methods: {
        setFilename(){

            let that    = this;
            let textbox = that.$el.querySelector('.form__file-input-wrapper span');
            let input   = that.$el.querySelector('.form__file-input-wrapper input');

            if(that.file == ''){
                textbox.textContent = 'Nog geen bestand gekozen';
            } else {
                textbox.textContent = that.file;
            }

            input.addEventListener('change', function() {
                that.file = this.value;
                textbox.textContent = that.file.split("\\").pop();
            })
        },
    },
});
